import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Form, Field } from 'react-final-form';
import FormTextField from '../form/FormTextField';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import AuthLayout from './AuthLayout';
import { AmtButton } from '../common/AmtButton';
import FormFilledInput from '../form/FormFilledInput';
import { styles } from './AuthStyles';

const logger = new Logger('ForgotPassword');

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			error: '',
		};
	}

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	submit = values => {
		const { location } = this.props;
		const { username } = queryString.parse(location.search);
		const { code, password } = values;
		Auth.forgotPasswordSubmit(username, code, password)
			.then(data => {
				logger.debug(data);
				this.props.onStateChange('signIn', {});
				this.setState({ delivery: null });
			})
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	//Validate if mandatory fields are filled
	validateSubmitForm = values => {
		const reLowerLetter = /(?=.*[a-z])/;
		const reUpperLetter = /(?=.*[A-Z])/;
		const reNumber = /(?=.*\d)/;
		const reSpecialCar = /(?=.*[-+_!@#$%^&*.,?])/;
		const errors = {};
		errors.password = [];
		if (!(values.code && values.code.length > 0)) {
			errors.code = 'app.error.mandatoryField';
		}
		if (!(values.password && values.password.length > 0)) {
			errors.password.push('app.error.mandatoryField');
		}
		if (values.password && !reLowerLetter.test(values.password)) {
			errors.password.push('auth.error.password.lowerChar');
		}
		if (values.password && !reUpperLetter.test(values.password)) {
			errors.password.push('auth.error.password.upperChar');
		}
		if (values.password && !reNumber.test(values.password)) {
			errors.password.push('auth.error.password.number');
		}
		if (values.password && !reSpecialCar.test(values.password)) {
			errors.password.push('auth.error.password.specialChar');
		}
		if (values.password && values.password.length < 8) {
			errors.password.push('auth.error.password.minLength');
		}
		return errors;
	};

	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.forgotPassword.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.submit}
						validate={this.validateSubmitForm}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container spacing={0} direction="column" justify="center" alignItems="center">
									<Grid container item className={classes.formItem}>
										<Typography align="flex-start" variant="body2" className={classes.field}>
											{t('app.auth.code.hint')}
										</Typography>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="code"
											name="code"
											type="password"
											label={t('app.auth.code.label')}
											className={classes.field}
											autoFocus={true}
										/>
									</Grid>
									{/* <Grid container item xs className={classes.formItem}>
										<Typography align="flex-start" variant="caption" className={classes.field}>
											{t('app.auth.signup.password.policy')}
										</Typography>
									</Grid> */}
									<Grid container item className={classes.formItem}>
										<FormControl fullWidth variant="filled" className={classes.field}>
											<InputLabel htmlFor="password">{t('app.auth.password.label')}</InputLabel>
											<Field
												fullWidth
												required
												component={FormFilledInput}
												id="password"
												name="password"
												errorlist="true"
												type={this.state.showPassword ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={this.handleClickShowPassword}
															onMouseDown={this.handleMouseDownPassword}
															edge="end"
														>
															{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											variant="contained"
											color="primary"
											type="submit"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.actions.submit')}
										</AmtButton>
									</Grid>
									<Grid container item justify="flex-end" className={classes.formItem}>
										<Button color="primary" onClick={this.gotoSignIn} className={classes.field}>
											{t('app.auth.signin.link.signIn')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					/>
				</Fragment>
			</AuthLayout>
		);
	}
}

ResetPassword.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withRouter(withStyles(styles)(ResetPassword)));
