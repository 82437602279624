import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Logger } from 'aws-amplify';

import PrivateRoute from './routes/PrivateRoute';
import AuthenticatorRoute from './routes/AuthenticatorRoute';
import Authenticator from './auth/Authenticator';
import PrivateApp from './AsyncPrivateApp';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import { withAuthContext } from './AuthContext';

const NoMatch = ({ location }) => (
	<Redirect to="/" />
);

const logger = new Logger('MainRoutes');
const history = createBrowserHistory();

const MainRoutes = ({ authCtx }) => {

	logger.debug('Will Render: ', authCtx);
	logger.debug('location: ', history.location);
	
	return (
		<Router history={history}>
			<Switch>
				<Route path="/privacy" component={PrivacyPolicy} />
				<AuthenticatorRoute path="/auth" component={Authenticator} authCtx={authCtx} />
				<PrivateRoute path="/" component={PrivateApp} authCtx={authCtx} />
				<Route component={NoMatch} />
			</Switch>
		</Router>
	);
};

export default withAuthContext(MainRoutes);
