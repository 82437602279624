import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

export default ({
	input: { name, onChange, value, ...restInput },
	meta,
	hideerror,
	innerRef,
	...rest
}) => {
	const { t } = useTranslation();
	return (
		<TextField
			{...rest}
			name={name}
			helperText={!hideerror && meta.touched ? t(meta.error) : undefined}
			error={Boolean(meta.error && meta.touched).valueOf()}
			InputProps={restInput}
			inputRef={innerRef}
			onChange={onChange}
			value={value}
			variant="filled"
		/>
	);
};
