import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	root: {
		borderRadius: '25px',
	},
}));

export const AmtButton = ({className, children, ...rest}) => {
	const classes = useStyles();
	return <Button className={clsx(classes.root, className)} {...rest}>{children}</Button>;
};

export default AmtButton;
