const config = {
	app: {
		baseUrl: process.env.REACT_APP_BASE_URL,
		logLevel: process.env.REACT_APP_LOG_LEVEL,
		cacheVersion: process.env.REACT_APP_CACHE_VERSION,
		cguVersion: process.env.REACT_APP_CGU_VERSION,
		appVersion: process.env.REACT_APP_VERSION,
	},
	features: {
		removeAccount: 'true' === (process.env.REACT_APP_FEATURE_REMOVE_ACCOUNT || 'false'),
		calendarNext: 'true' === (process.env.REACT_APP_FEATURE_CALENDAR_NEXT || 'false'),
	},
	google: {
		analyticsId: process.env.REACT_APP_GOOGLE_ANALITICS_ID,
		gcmPublicKey: process.env.REACT_APP_GOOGLE_GCM_PUBLIC_KEY,
	},
	mapbox: {
		apiAccessToken: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN,
	},
	hotjar: {
		id: process.env.REACT_APP_HOTJAR_ID,
		version: process.env.REACT_APP_HOTJAR_VERSION,
	},
	amplify_config: {
		Auth: {
			region: process.env.REACT_APP_AWS_DEFAULT_REGION,
			identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
			userPoolId: process.env.REACT_APP_USER_POOL_ID,
			mandatorySignIn: false,
			userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
			oauth: {
				domain: process.env.REACT_APP_USER_POOL_DOMAIN,
				scope: [
					'email',
					'profile',
					'openid',
					'phone',
					'aws.cognito.signin.user.admin',
				],
				redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
				redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
				responseType: 'code',
			},
		},
		Analytics: {
			// OPTIONAL - Allow recording session events. Default is true.
			autoSessionRecord: true,
			AWSPinpoint: {
				// OPTIONAL -  Amazon Pinpoint App Client ID
				appId: process.env.REACT_APP_PINPOINT_APPID,
				// OPTIONAL -  Amazon service region
				region: process.env.REACT_APP_AWS_DEFAULT_REGION,
				mandatorySignIn: false,
			},
		},
		aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
		aws_appsync_region: process.env.REACT_APP_AWS_DEFAULT_REGION,
		aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE,
	},
};

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config,
};
