import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { Logger} from 'aws-amplify';

import { withAuthContext } from '../AuthContext';

const logger = new Logger("AuthenticatorRoute");

class AuthenticatorRoute extends React.Component {
	state = {
		loaded: false,
		isAuthenticated: false
  };
  
	componentDidMount() {
		this.unlisten = this.props.history.listen(() => {
			this.props.authCtx.updateCurrentUser();
		});
	}
	componentWillUnmount() {
		this.unlisten();
	}
	render() {
		const { component: Component, authCtx, ...rest } = this.props;
		logger.debug("Will Render: ",authCtx);
		
		const isAuthenticated = authCtx.user && authCtx.user.username ? true : false;
		const isLoaded = authCtx.isLoaded;
		if (!isLoaded) return null;

		
		logger.debug("isAuthenticated: ",isAuthenticated);
		return (
			<Route
				{...rest}
				render={(props) => {
					return isAuthenticated ? (
						<Redirect
							to={{
								pathname: '/'
							}}
						/>
					) : (
						<Component {...props} />
					);
				}}
			/>
		);
	}
}

export default withAuthContext(withRouter(AuthenticatorRoute));
