import { Logger } from 'aws-amplify';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import browser from 'browser-detect';

import config from '../config';

const logger = new Logger('remote-logs');

/**
 * Lib to mutualise Remote Logs sending
 * 
 */

const customJSON = log => {
	logger.debug("raw log: ", log, JSON.stringify(log));
	const msgJson = JSON.parse(log.message);
	const customLog = {
		error: msgJson.error,
		info: JSON.stringify(msgJson.info),
		location: JSON.stringify(msgJson.location),
		browserInfo: JSON.stringify(msgJson.browserInfo),
		level: log.level.label,
        stacktrace: msgJson.stack,
        version: config.app.appVersion,
	};
	logger.debug("customLog: ", customLog);
	return customLog;
};

remote.apply(log, {
	format: customJSON,
	url: `${config.app.baseUrl}/restapi/logs`,
});
log.enableAll();

/**
 * Send Error to backend
 * 
 * @param {*} error 
 * @param {*} info 
 */
export const logErrorRemote = (error, info) => {
    const { location } = window;
    const browserInfo = browser();
    log.error(JSON.stringify({ error: error.message, stack: error.stack, info, location, browserInfo }));
}

export const logInfoRemote = (info) => {
    const { location } = window;
    const browserInfo = browser();
    log.info(JSON.stringify({ info, location, browserInfo }));
}