import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import CGUContent from '../CGUContent';

class CGUDialog extends React.Component {
	render() {
		const { open, handleClose, t } = this.props;

		return (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					<Typography variant="h5" gutterBottom paragraph={true}>
						{t('cgu.mainTitle')}
					</Typography>
					<Typography
						variant="subtitle1"
						gutterBottom
						paragraph={true}
					>
						{t('cgu.subTitle')}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<CGUContent />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{t('app.actions.close')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

CGUDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

export default withTranslation()(CGUDialog);