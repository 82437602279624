import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { Logger } from 'aws-amplify';

import { withAuthContext } from '../AuthContext';

const logger = new Logger("PrivateRoute");

class PrivateRoute extends React.Component {
	state = {
		loaded: false,
		isAuthenticated: false
	};

	componentDidMount() {
		this.unlisten = this.props.history.listen(() => {
			this.props.authCtx.updateCurrentUser();
		});
	}
	componentWillUnmount() {
		this.unlisten();
	}

	render() {
		const { component: Component, authCtx, match, location, ...rest } = this.props;
		logger.debug("Will Render: ", authCtx);
		logger.debug("match: ", match);
		logger.debug("location: ", location);

		const isAuthenticated = authCtx.user && authCtx.user.username ? true : false;
		const isLoaded = authCtx.isLoaded;
		if (!isLoaded) return null;

		let redirectLocation = false;
		if (isAuthenticated && localStorage.getItem('postSigninLocation')) {
			redirectLocation = JSON.parse(localStorage.getItem('postSigninLocation'));
			localStorage.removeItem('postSigninLocation');
		}
		if (!isAuthenticated && location.pathname === '/invitation') {
			localStorage.setItem('postSigninLocation', JSON.stringify(location));
		}

		logger.debug("isAuthenticated: ", isAuthenticated);
		return (
			<Route
				{...rest}
				render={(props) => {
					return isAuthenticated ?
						redirectLocation ? (
							<Redirect
								to={redirectLocation}
							/>
						) :	(
								<Component {...props} />
							) : (
							<Redirect
								to={{
									pathname: '/auth/welcome'
								}}
							/>
						);
				}}
			/>
		);
	}
}

export default withAuthContext(withRouter(PrivateRoute));
