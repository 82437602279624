import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import { styles } from './AuthStyles';
import AuthLayout from './AuthLayout';
import FormFilledInput from '../form/FormFilledInput';

const logger = new Logger('RequireNewPassword');

class RequireNewPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	change = async values => {
		const user = this.props.authData;
		const { password } = this.inputs;
		const { requiredAttributes } = user.challengeParam;
		Auth.completeNewPassword(user, password, requiredAttributes)
			.then(user => {
				logger.debug('complete new password', user);
				if (user.challengeName === 'SMS_MFA') {
					this.changeState('confirmSignIn', user);
				} else {
					this.changeState('signedIn');
				}
			})
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	//Validate if mandatory fields are filled
	validate = values => {
		const reLowerLetter = /(?=.*[a-z])/;
		const reUpperLetter = /(?=.*[A-Z])/;
		const reNumber = /(?=.*\d)/;
		const reSpecialCar = /(?=.*[-+_!@#$%^&*.,?])/;
		const errors = {};
		errors.password = [];
		if (!(values.password && values.password.length > 0)) {
			errors.password.push('app.error.mandatoryField');
		}
		if (values.password && !reLowerLetter.test(values.password)) {
			errors.password.push('auth.error.password.lowerChar');
		}
		if (values.password && !reUpperLetter.test(values.password)) {
			errors.password.push('auth.error.password.upperChar');
		}
		if (values.password && !reNumber.test(values.password)) {
			errors.password.push('auth.error.password.number');
		}
		if (values.password && !reSpecialCar.test(values.password)) {
			errors.password.push('auth.error.password.specialChar');
		}
		if (values.password && values.password.length < 8) {
			errors.password.push('auth.error.password.minLength');
		}
		if (values.password !== values.passwordCheck) {
			errors.passwordCheck = 'auth.error.password.notMatching';
		}
		return errors;
	};

	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.requireNewPassword.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.confirm}
						validate={this.validate}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container spacing={0} direction="column" justify="center" alignItems="center">
									<Grid container item justify="center" className={classes.formItem}>
										<Typography align="center" variant="h6" className={classes.field}>
											{t('app.auth.requireNewPassword.welcome')}
										</Typography>
										<Typography align="flex-start" variant="subtitle1" className={clsx(classes.field, classes.subtitle)}>
											{t('app.auth.requireNewPassword.subtitle')}
										</Typography>
									</Grid>
									{/* <Grid container item xs className={classes.formItem}>
										<Typography align="flex-start" variant="caption" className={classes.field}>
											{t('app.auth.signup.password.policy')}
										</Typography>
									</Grid> */}
									<Grid container item className={classes.formItem}>
										<FormControl fullWidth variant="filled" className={classes.field}>
											<InputLabel htmlFor="password">{t('app.auth.password.label')}</InputLabel>
											<Field
												fullWidth
												required
												component={FormFilledInput}
												id="password"
												name="password"
												errorlist="true"
												type={this.state.showPassword ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={this.handleClickShowPassword}
															onMouseDown={this.handleMouseDownPassword}
															edge="end"
														>
															{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Button
											variant="contained"
											color="primary"
											type="submit"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.actions.submit')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					/>
				</Fragment>

			</AuthLayout>
		);
	}
}

RequireNewPassword.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(RequireNewPassword));
