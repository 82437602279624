import React, { Component } from 'react';
import { Auth, Hub, Logger, Analytics } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';

import client from '../Apollo';
import config from '../config';
import MainRoutes from './MainRoutes';
import { AuthContext } from './AuthContext';
// import Splash from './Splash';

const logger = new Logger('App');

Logger.LOG_LEVEL = config.app.logLevel;


Analytics.autoTrack('session', {
	// REQUIRED, turn on/off the auto tracking
	enable: true,
	provider: 'AWSPinpoint'
});

Analytics.autoTrack('pageView', {
	// REQUIRED, turn on/off the auto tracking
	enable: true,
	eventName: 'pageView',
	type: 'SPA',
	provider: 'AWSPinpoint',
	getUrl: () => {
		// the default function
		return window.location.origin + window.location.pathname;
	}
});

class App extends Component {
	state = {
		currentUser: {},
		isLoaded: false
	};

	componentDidMount() {
		this.updateCurrentUser();
		Hub.listen('auth', this.listAuthEvents);
	}

	listAuthEvents = (data) => {
		const { channel, payload } = data;
		if (channel === 'auth' && payload.event !== 'signIn' && !payload.event.includes("failure")) {
			this.updateCurrentUser();
		}
	}

	updateCurrentUser = async (user) => {
		logger.debug('updateCurrentUser called: ', user);
		if (user) {
			this.setState({ currentUser: user });
			return;
		}
		try {
			const user = await Auth.currentAuthenticatedUser();

			this.setState({ currentUser: user, isLoaded: true });
		} catch (err) {
			this.setState({ currentUser: null, isLoaded: true });
		}
	};

	render() {
		return (
			<AuthContext.Provider
				value={{
					user: this.state.currentUser,
					updateCurrentUser: this.updateCurrentUser,
					isLoaded: this.state.isLoaded
				}}
			>
				<ApolloProvider client={client}>
						<SnackbarProvider maxSnack={3}>
							<MainRoutes />
						</SnackbarProvider>					
				</ApolloProvider>
			</AuthContext.Provider>
		);
	}
}

export default App;
