import 'typeface-roboto';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import Amplify from 'aws-amplify';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import ReactPWAInstallProvider, { useReactPWAInstall } from "@amtpub/react-pwa-install";
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';

import './i18n/i18nApp';
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';
import config from './config';
import { defaultTheme } from './controlers/ThemeControler';
// import config from './config';
import App from './components/App';
import Splash from './components/Splash';
import ErrorBundary from './components/ErrorBundary';

Amplify.configure(config.amplify_config);


// Render the main app react component into the app div.
// For more details see: https://facebook.github.io/react/docs/top-level-api.html#react.render
render(
	<Suspense fallback={<Splash height='85vh' />}>
		<ErrorBundary>
			<MuiThemeProvider theme={defaultTheme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<App />
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</ErrorBundary>
	</Suspense>,
	document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
