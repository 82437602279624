import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import config from '../config';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: 'en',
        preload: ['en', 'fr'],
        ns: ['frontend2','plants2', 'links2'],
        defaultNS: 'frontend2',        
        keySeparator: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: `${config.app.baseUrl}/shared/locales/{{lng}}/{{ns}}.json`,
            crossDomain: true,
        },
        // react: {
        //     useSuspense: false
        // }
    });

export default i18n;