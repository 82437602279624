import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Auth, Logger, JS } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import MuiLink from '@material-ui/core/Link';

import AuthLayout from './AuthLayout';
import { AmtButton } from '../common/AmtButton';
import FormTextField from '../form/FormTextField';
import CGUDialog from '../accounts/CGUDialog';
import config from '../../config';
import { styles } from './AuthStyles';

const logger = new Logger('SignInForm');

class SignInForm extends React.Component {
	constructor() {
		super();
		this.state = {
			error: '',
			message: '',
			cguFormOpen: false,
		};
	}

	handleOpenCguForm = event => {
		event.preventDefault();
		this.setState({ cguFormOpen: true });
	};

	handleCloseCguForm = () => {
		this.setState({ cguFormOpen: false });
	};

	gotoSignUp = () => {
		// to switch the authState to 'signUp'
		this.props.onStateChange('signUp', {});
	};

	gotoChangePasswd = () => {
		// to switch the authState to 'forgotPassword'
		this.props.onStateChange('forgotPassword', {});
	};

	checkContact = user => {
		Auth.verifiedContact(user).then(data => {
			if (!JS.isEmpty(data.verified)) {
				this.props.onStateChange('signedIn', user);
			} else {
				user = Object.assign(user, data);
				this.props.onStateChange('verifyContact', user);
			}
		});
	};

	//Validate if mandatory fields are filled
	validate = values => {
		const re = /\S+@\S+\.\S+/;
		const errors = {};
		if (!values.username) {
			errors.username = 'app.error.mandatoryField';
		}
		if (values.username && !re.test(values.username)) {
			errors.username = 'auth.error.emailFormat';
		}
		if (!values.password) {
			errors.password = 'app.error.mandatoryField';
		}
		return errors;
	};

	saveCguAccept = () => {
		window.localStorage.setItem('amt_cgu', 'accepted');
	};

	signIn = async values => {
		const { username, password } = values;
		logger.debug('signIn: ', username, password);
		Auth.signIn(username, password)
			.then(user => {
				logger.debug(user);
				if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
					logger.debug('confirm user with ' + user.challengeName);
					this.props.onStateChange('confirmSignIn', user);
				} else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					logger.debug('require new password', user.challengeParam);
					this.props.onStateChange('requireNewPassword', user);
				} else if (user.challengeName === 'MFA_SETUP') {
					logger.debug('TOTP setup', user.challengeParam);
					this.props.onStateChange('TOTPSetup', user);
				} else {
					this.checkContact(user);
				}
			})
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	loginWithFB = () => {
		logger.debug('loginWithFB');
		const { domain, redirectSignIn, responseType } = config.amplify_config.Auth.oauth;

		const clientId = config.amplify_config.Auth.userPoolWebClientId;
		const auth_url =
			'https://' +
			domain +
			'/oauth2/authorize?redirect_uri=' +
			redirectSignIn +
			'&response_type=' +
			responseType +
			'&client_id=' +
			clientId;
		const auth_url_facebook = auth_url + '&identity_provider=Facebook';
		// Launch hosted UI
		logger.debug('redirectToHostedUI - URL = ' + auth_url_facebook);
		this.saveCguAccept();
		window.location.assign(auth_url_facebook);
	};

	loginWithGoogle = () => {
		logger.debug('loginWithGoogle');
		const { domain, redirectSignIn, responseType } = config.amplify_config.Auth.oauth;

		const clientId = config.amplify_config.Auth.userPoolWebClientId;
		const auth_url =
			'https://' +
			domain +
			'/oauth2/authorize?redirect_uri=' +
			redirectSignIn +
			'&response_type=' +
			responseType +
			'&client_id=' +
			clientId;
		const auth_url_google = auth_url + '&identity_provider=Google';
		// Launch hosted UI
		logger.debug('redirectToHostedUI - URL = ' + auth_url_google);
		this.saveCguAccept();
		window.location.assign(auth_url_google);
	};

	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.signin.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body1">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.signIn}
						validate={this.validate}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container direction="column" justify="flex-start" alignItems="center">
									{/* <Grid container item xs justify="center" className={classes.formItem}>
										<Typography align="center" variant="h6" className={classes.field}>
											{t('app.auth.signin.welcome')}
										</Typography>
									</Grid> */}
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="username"
											name="username"
											type="email"
											label={t('app.auth.email.label')}
											className={classes.field}
											autoFocus={true}
										/>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="password"
											name="password"
											type="password"
											label={t('app.auth.password.label')}
											className={classes.field}
										/>
									</Grid>
									<Grid container item justify="center" alignItems="center" className={classes.formItem}>
										<Typography variant="body2" align="center">
											{t('app.auth.cgu.signin')}
										</Typography>
										<MuiLink component="button" variant="body2" onClick={this.handleOpenCguForm}>
											{t('app.auth.cgu.link')}
										</MuiLink>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											type="submit"
											variant="contained"
											color="primary"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.auth.signin.action')}
										</AmtButton>
									</Grid>
									<Grid container item align="center" justify="center" className={classes.formItem}>
										<Typography align="center" variant="caption">
											{t('app.auth.signin.or')}
										</Typography>
									</Grid>
									<Grid container item className={classes.formItem}>
										<FacebookLoginButton
											onClick={this.loginWithFB}
											align="center"
											style={{
												width: '100%',
												borderRadius: '25px',
												marginLeft: '1em',
												marginRight: '1em',
												paddingLeft: '20px',
												margin: 'auto',
												height: '44px',
											}}
										>
											<Typography variant="button">{t('app.auth.signin.facebook')}</Typography>
										</FacebookLoginButton>
									</Grid>
									<Grid container item className={classes.formItem}>
										<GoogleLoginButton
											onClick={this.loginWithGoogle}
											align="center"
											style={{
												width: '100%',
												borderRadius: '25px',
												marginLeft: '1em',
												marginRight: '1em',
												paddingLeft: '20px',
												margin: 'auto',
												height: '44px',
											}}
										>
											<Typography variant="button">{t('app.auth.signin.google')}</Typography>
										</GoogleLoginButton>
									</Grid>
									<Grid
										container
										item
										direction="row"
										justify="center"
										alignItems="center"
										className={classes.formItem}
									>
										<Typography align="center" variant="body2">
											{t('app.auth.account.no')}
										</Typography>
										<Button color="primary" onClick={this.gotoSignUp} className={classes.field}>
											{t('app.auth.signin.link.signUp')}
										</Button>
									</Grid>
									<Grid container item alignItems="center" justify="center" className={classes.formItem}>
										<Button color="primary" onClick={this.gotoChangePasswd} className={classes.field}>
											{t('app.auth.signin.link.passwdLost')}
										</Button>
									</Grid>
									{/* <Grid container item xs justify="space-between" className={classes.formItem}>
										<Button color="secondary" onClick={this.gotoChangePasswd} className={classes.field}>
											{t('app.auth.signin.link.passwdLost')}
										</Button>
										<Button color="primary" onClick={this.gotoSignUp} className={classes.field}>
											{t('app.auth.signin.link.signUp')}
										</Button>
									</Grid> */}
								</Grid>
							</form>
						)}
					/>
				</Fragment>
				<CGUDialog open={this.state.cguFormOpen} handleClose={this.handleCloseCguForm} />
			</AuthLayout>
		);
	}
}

SignInForm.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(SignInForm));
