import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import FormTextField from '../form/FormTextField';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import AuthLayout from './AuthLayout';
import { AmtButton } from '../common/AmtButton';
import { styles } from './AuthStyles';

const logger = new Logger('ForgotPassword');

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			delivery: null,
			username: '',
			error: '',
		};
	}

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	send = values => {
		const { history } = this.props;
		const { username } = values;
		logger.debug('send to: ', username);
		Auth.forgotPassword(username)
			.then(data => {
				logger.debug(data);
				history.push(`/auth/resetPassword?username=${username}`);
			})
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	//Validate if mandatory fields are filled
	validateSendForm = values => {
		const errors = {};
		if (!(values.username && values.username.length > 0)) {
			errors.username = 'app.error.mandatoryField';
		}
		return errors;
	};

	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.forgotPassword.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.send}
						validate={this.validateSendForm}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container spacing={0} direction="column" justify="center" alignItems="center">
									<Grid container item justify="center" className={classes.formItem}>
										<Typography align="center" variant="h6" className={classes.field}>
											{t('app.auth.forgotPassword.welcome')}
										</Typography>
										<Typography align="flex-start" variant="subtitle1" className={clsx(classes.field, classes.subtitle)}>
											{t('app.auth.forgotPassword.subtitle')}
										</Typography>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="username"
											name="username"
											type="text"
											label={t('app.auth.email.label')}
											className={classes.field}
											autoFocus={true}
										/>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											variant="contained"
											color="primary"
											type="submit"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.actions.submit')}
										</AmtButton>
									</Grid>
									<Grid container item justify="flex-end" className={classes.formItem}>
										<Button color="primary" onClick={this.gotoSignIn} className={classes.field}>
											{t('app.auth.signin.link.signIn')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					/>
				</Fragment>
			</AuthLayout>
		);
	}
}

ForgotPassword.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withRouter(withStyles(styles)(ForgotPassword)));
