import React from 'react';
import { makeStyles } from '@material-ui/core';

import config from '../config';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        [theme.breakpoints.up('xs')]: {
            height: 200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up('sm')]: {
            height: 300,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}))

const Splash = ({ height }) => {
    const classes = useStyles();
    return (
        <div className={classes.container} style={{
            height,
        }}>
            <img
                src={config.app.baseUrl + '/shared/images/logo.png'}
                alt="adopt my tomato logo"
                className={classes.logo}
            />
        </div >
    );
}

export default Splash;
