import { createMuiTheme } from '@material-ui/core/styles';
import { red, amber } from '@material-ui/core/colors';

export const defaultTheme = createMuiTheme({
	palette: {
		primary: {
			main: red[700],
			light: red['A100']
		},
		secondary: {
			main: amber[700],
			light: amber['A100'],
		},
		error: red,
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
});


const enterpriseTheme = createMuiTheme({
	palette: {
		primary: {
			main: amber[700],
		},
		secondary: {
			main: red[700],
		},
		error: red,
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
});

export const themeForProfile = (userProfile) => {
    return userProfile.type === 'enterprise' ? enterpriseTheme : defaultTheme;
}

