import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import MuiLink from '@material-ui/core/Link';

import AuthLayout from './AuthLayout';
import { AmtButton } from '../common/AmtButton';
import FormTextField from '../form/FormTextField';
import FormFilledInput from '../form/FormFilledInput';
import CGUDialog from '../accounts/CGUDialog';
import { styles } from './AuthStyles';
import config from '../../config';

const logger = new Logger('SignUp');

//SIGN UP
class SignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			cguFormOpen: false,
			showPassword: false,
		};
	}

	handleOpenCguForm = event => {
		event.preventDefault();
		this.setState({ cguFormOpen: true });
	};

	handleCloseCguForm = () => {
		this.setState({ cguFormOpen: false });
	};

	gotoSignIn = () => {
		// to switch the authState to 'signUp'
		this.props.onStateChange('signIn', {});
	};

	handleClickShowPassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	signUp = async values => {
		const { password, email } = values;
		const username = email;
		logger.debug('signUp for email: ', email);
		const attributes = {
			email: email,
		};
		Auth.signUp({
			username,
			email,
			password,
		})
			.then(() => {
				logger.debug('gotoConfirmSignUp for email: ', email);
				this.saveCguAccept();
				this.props.onStateChange('confirmSignUp', attributes);
			})
			.catch(err => {
				logger.debug('Signup error for email: ', email);
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	saveCguAccept = () => {
		window.localStorage.setItem('amt_cgu', 'accepted');
	};

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	gotoConfirmSignUp = () => {
		// to switch the authState to 'confirmSignUp'
		logger.debug('gotoConfirmSignUp');
		this.props.onStateChange('confirmSignUp');
	};

	loginWithFB = () => {
		logger.debug('loginWithFB');
		const { domain, redirectSignIn, responseType } = config.amplify_config.Auth.oauth;

		const clientId = config.amplify_config.Auth.userPoolWebClientId;
		const auth_url =
			'https://' +
			domain +
			'/oauth2/authorize?redirect_uri=' +
			redirectSignIn +
			'&response_type=' +
			responseType +
			'&client_id=' +
			clientId;
		const auth_url_facebook = auth_url + '&identity_provider=Facebook';
		// Launch hosted UI
		logger.debug('redirectToHostedUI - URL = ' + auth_url_facebook);
		this.saveCguAccept();
		window.location.assign(auth_url_facebook);
	};

	loginWithGoogle = () => {
		logger.debug('loginWithGoogle');
		const { domain, redirectSignIn, responseType } = config.amplify_config.Auth.oauth;

		const clientId = config.amplify_config.Auth.userPoolWebClientId;
		const auth_url =
			'https://' +
			domain +
			'/oauth2/authorize?redirect_uri=' +
			redirectSignIn +
			'&response_type=' +
			responseType +
			'&client_id=' +
			clientId;
		const auth_url_google = auth_url + '&identity_provider=Google';
		// Launch hosted UI
		logger.debug('redirectToHostedUI - URL = ' + auth_url_google);
		this.saveCguAccept();
		window.location.assign(auth_url_google);
	};

	//Validate if mandatory fields are filled
	validate = values => {
		const reEmail = /\S+@\S+\.\S+/;
		const reLowerLetter = /(?=.*[a-z])/;
		const reUpperLetter = /(?=.*[A-Z])/;
		const reNumber = /(?=.*\d)/;
		const reSpecialCar = /(?=.*[-+_!@#$%^&*.,?])/;
		logger.debug('validate - values : ', values);
		const errors = {};
		errors.password = [];
		if (!(values.email && values.email.length > 0)) {
			errors.email = 'app.error.mandatoryField';
		}
		if (values.username && !reEmail.test(values.username)) {
			errors.username = 'auth.error.emailFormat';
		}
		if (!(values.password && values.password.length > 0)) {
			errors.password.push('app.error.mandatoryField');
		}
		if (values.password && !reLowerLetter.test(values.password)) {
			errors.password.push('auth.error.password.lowerChar');
		}
		if (values.password && !reUpperLetter.test(values.password)) {
			errors.password.push('auth.error.password.upperChar');
		}
		if (values.password && !reNumber.test(values.password)) {
			errors.password.push('auth.error.password.number');
		}
		if (values.password && !reSpecialCar.test(values.password)) {
			errors.password.push('auth.error.password.specialChar');
		}
		if (values.password && values.password.length < 8) {
			errors.password.push('auth.error.password.minLength');
		}
		if (errors.password.length === 0) {
			errors.password = undefined;
		}

		return errors;
	};

	//Rendu final
	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.signup.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.signUp}
						validate={this.validate}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container spacing={0} direction="column" justify="center" alignItems="center">
									{/* <Grid container item xs justify="center" className={classes.formItem}>
										<Typography align="center" variant="h6" className={classes.field}>
											{t('app.auth.signup.welcome')}
										</Typography>
									</Grid> */}
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="email"
											name="email"
											type="email"
											label={t('app.auth.email.label')}
											className={classes.field}
											autoFocus={true}
										/>
									</Grid>
									{/* <Grid container item xs className={classes.formItem}>
										<Typography align="flex-start" variant="caption" className={classes.field}>
											{t('app.auth.signup.password.policy')}
										</Typography>
									</Grid> */}
									<Grid container item className={classes.formItem}>
										<FormControl fullWidth variant="filled" className={classes.field}>
											<InputLabel htmlFor="password">{t('app.auth.password.label')}</InputLabel>
											<Field
												fullWidth
												required
												component={FormFilledInput}
												id="password"
												name="password"
												errorlist="true"
												type={this.state.showPassword ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={this.handleClickShowPassword}
															onMouseDown={this.handleMouseDownPassword}
															edge="end"
														>
															{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid container item justify="center" alignItems="center" className={classes.formItem}>
										<Typography variant="body2" align="center">
											{t('app.auth.cgu.signup')}
										</Typography>
										<MuiLink component="button" variant="body2" onClick={this.handleOpenCguForm}>
											{t('app.auth.cgu.link')}
										</MuiLink>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											variant="contained"
											color="primary"
											type="submit"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.auth.signup.action')}
										</AmtButton>
									</Grid>
									<Grid container item align="center" justify="center" className={classes.formItem}>
										<Typography align="center" variant="caption">
											{t('app.auth.signin.or')}
										</Typography>
									</Grid>
									<Grid container item className={classes.formItem}>
										<FacebookLoginButton
											onClick={this.loginWithFB}
											align="center"
											style={{
												width: '100%',
												borderRadius: '25px',
												marginLeft: '1em',
												marginRight: '1em',
												paddingLeft: '20px',
												margin: 'auto',
												height: '44px',
											}}
										>
											<Typography variant="button">{t('app.auth.signin.facebook')}</Typography>
										</FacebookLoginButton>
									</Grid>
									<Grid container item className={classes.formItem}>
										<GoogleLoginButton
											onClick={this.loginWithGoogle}
											align="center"
											style={{
												width: '100%',
												borderRadius: '25px',
												marginLeft: '1em',
												marginRight: '1em',
												paddingLeft: '20px',
												margin: 'auto',
												height: '44px',
											}}
										>
											<Typography variant="button">{t('app.auth.signin.google')}</Typography>
										</GoogleLoginButton>
									</Grid>
									<Grid
										container
										item
										direction="row"
										justify="center"
										alignItems="center"
										className={classes.formItem}
									>
										<Typography align="center" variant="body2">
											{t('app.auth.account.yes')}
										</Typography>
										<Button color="primary" onClick={this.gotoSignIn} className={classes.field}>
											{t('app.auth.signin.link.signIn')}
										</Button>
									</Grid>
									<Grid container item alignItems="center" justify="center" className={classes.formItem}>
										<Button color="primary" onClick={this.gotoConfirmSignUp} className={classes.field}>
											{t('app.auth.links.gotoconfirmsignup')}
										</Button>
									</Grid>
									{/* <Grid container item xs justify="space-between" className={classes.formItem}>
										<Button color="secondary" onClick={this.gotoConfirmSignUp} className={classes.field}>
											{t('app.auth.links.gotoconfirmsignup')}
										</Button>
										<Button color="primary" onClick={this.gotoSignIn} className={classes.field}>
											{t('app.auth.signin.link.signIn')}
										</Button>
									</Grid> */}
								</Grid>
							</form>
						)}
					/>
				</Fragment>
				<CGUDialog open={this.state.cguFormOpen} handleClose={this.handleCloseCguForm} />
			</AuthLayout>
		);
	}
}

SignUp.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(SignUp));
