import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';


import SignInForm from './SignInForm';
import Welcome from './Welcome';
import Connecting from './Connecting';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ConfirmSignIn from './ConfirmSignIn';
import ConfirmSignUp from './ConfirmSignUp';
import RequireNewPassword from './RequireNewPassword';

class Authenticator extends React.Component {
	state = {
		currentState: 'welcome',
		authData: {},
	};

	switchState = (currentState, authData) => {
		const { history } = this.props;
		this.setState({
			currentState,
		});
		if (authData) {
			this.setState({
				authData,
			});
		}
		if (currentState === 'signedIn') {
			history.push('/');
		}
		history.push(`/auth/${currentState}`);
	};

	render() {
		const { authData } = this.state;
		return (
			<Fragment>
				<Route
					exact
					path="/auth/welcome"
					component={() => <Welcome {...this.props} onStateChange={this.switchState} />}
				/>
				<Route
					exact
					path="/auth/signIn"
					component={() => <SignInForm {...this.props} onStateChange={this.switchState} />}
				/>
				<Route
					exact
					path="/auth/signUp"
					component={() => <SignUp {...this.props} onStateChange={this.switchState} />}
				/>
				<Route
					exact
					path="/auth/forgotPassword"
					component={() => <ForgotPassword {...this.props} onStateChange={this.switchState} />}
				/>
				<Route
					exact
					path="/auth/resetPassword"
					component={() => <ResetPassword {...this.props} onStateChange={this.switchState} />}
				/>
				<Route
					exact
					path="/auth/confirmSignIn"
					component={() => <ConfirmSignIn {...this.props} onStateChange={this.switchState} authData={authData} />}
				/>
				<Route
					exact
					path="/auth/confirmSignUp"
					component={() => <ConfirmSignUp {...this.props} onStateChange={this.switchState} authData={authData} />}
				/>
				<Route
					exact
					path="/auth/requireNewPassword"
					component={() => (
						<RequireNewPassword {...this.props} onStateChange={this.switchState} authData={authData} />
					)}
				/>
				<Route
					exact
					path="/auth/connecting"
					component={() => (
						<Connecting {...this.props} />
					)}
				/>
			</Fragment>
		);
	}
}

export default withRouter(Authenticator);
