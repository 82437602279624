import React, { Suspense, lazy } from 'react';

import AppLoading from './AppLoading';
const PrivateApp = lazy(() => import('./PrivateApp'));

export default function AsyncPrivateApp() {
	return (
		<Suspense fallback={<AppLoading />}>
			<PrivateApp />
		</Suspense>
	);
}
