import { Auth } from 'aws-amplify';
import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import config from '../config';

const url = config.amplify_config.aws_appsync_graphqlEndpoint;
const region = config.amplify_config.aws_appsync_region;
const auth = {
  type: config.amplify_config.aws_appsync_authenticationType,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth })
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  defaultOptions,
});

export default client;
