//CSS
export const styles = theme => ({
	messages: {
		padding: theme.spacing(1),
		width: '100%',
	},
	form: {
		height: '100%',
	},
	formItem: {
		paddingTop: '0.5em',
		maxWidth: '400px',
		width: '100%',
    },
    additionalFormItem: {
		maxWidth: '400px',
	},
	field: {
		// display: 'flex',
		margin: 'auto',
		marginLeft: '1.5em',
		marginRight: '1.5em',
	},
	subtitle: {
		marginTop: '2em',
	},
	button: {
		display: 'flex',
		margin: 'auto',
		padding: '10px',
		marginLeft: '1.5em',
		marginRight: '1.5em',
		width: '100%',
		height: '44px',
	},
});