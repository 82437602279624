import React from 'react';
import PropTypes from 'prop-types';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import config from '../../config';

const styles = theme => ({
	bottomImage: {
		backgroundImage: 'url(' + config.app.baseUrl + '/shared/images/vegetables_homepage.svg)',
		backgroundSize: 'contain',
		backgroundPosition: 'bottom',
		backgroundRepeat: 'no-repeat',
		height: '30vh',
		bottom: 0,
		position: 'fixed',
		width: '100%',
		zIndex: -1,
		opacity: 0.4,
	},
	background: {
		height: '100%',
		width: '100%',
		display: 'flex',
	},
	container: {
		height: '100%',
		width: '100%',
	},
	content: {
		width: '100%',
		alignItems: 'center',
	},
	formContent: {
		width: '100%',
		height: '100%',
		alignItems: 'stretch'
		// overflowY: 'scroll',
	},
	title: {
		marginTop: '0.5em',
	},
	media: {
		[theme.breakpoints.up('xs')]: {
			height: 50,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		[theme.breakpoints.up('sm')]: {
			height: 200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
});

const AuthLayout = props => {
	// const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.up('sm'));

	const { classes, children } = props;

	return (
		<div className={classes.background}>
			<Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
				<Grid item>
					<img src={config.app.baseUrl + '/shared/images/logo.png'} className={classes.media} alt="Logo" />
				</Grid>
				<Grid item className={classes.formContent}>
					{children}
				</Grid>
				{/* <Grid item sm/> */}
			</Grid>
			<div className={classes.bottomImage} />
		</div>
	);
};

AuthLayout.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthLayout);
