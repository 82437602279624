import React, { Component } from 'react';
import { Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import log from 'loglevel';
// import remote from 'loglevel-plugin-remote';
import browser from 'browser-detect';

import AmtButton from './common/AmtButton';
import { logErrorRemote } from '../libs/remote-logs';
import config from '../config';

const logger = new Logger('ErrorBoundary');

const styles = theme => ({
	grid: {
		height: '85vh',
	},
	buttonItem: {
		paddingTop: '1em',
	},
	media: {
		[theme.breakpoints.up('xs')]: {
			height: 200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		[theme.breakpoints.up('sm')]: {
			height: 300,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	button: {
		width: '100%',
		display: 'flex',
		margin: 'auto',
		padding: '10px',
		maxWidth: '360px',
	},
});

// const customJSON = log => {
// 	logger.debug("raw log: ", log, JSON.stringify(log));
// 	const msgJson = JSON.parse(log.message);
// 	const customLog = {
// 		error: msgJson.error,
// 		info: JSON.stringify(msgJson.info),
// 		location: JSON.stringify(msgJson.location),
// 		browserInfo: JSON.stringify(msgJson.browserInfo),
// 		level: log.level.label,
// 		stacktrace: msgJson.stack,
// 	};
// 	logger.debug("customLog: ", customLog);
// 	return customLog;
// };

// remote.apply(log, {
// 	format: customJSON,
// 	url: `${config.app.baseUrl}/restapi/logs`,
// });
// log.enableAll();

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		const { location } = window;
		const browserInfo = browser();
		// log the error to our server with loglevel
		logger.error({ error, info, location, browserInfo });
		// log.error(JSON.stringify({ error: error.message, stack: error.stack, info, location, browserInfo }));
		logErrorRemote(error, info)
	}

	render() {
		const { classes, t } = this.props;

		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
					<Grid item>
						<img src={config.app.baseUrl + '/shared/images/sad_tomato.svg'} className={classes.media} alt="" />
					</Grid>
					<Grid item>
						<Typography variant="h6" gutterBottom align="center">
							{t('app.global.error')}
						</Typography>
					</Grid>
					<Grid item className={classes.buttonItem}>
						<Grid container item xs>
							<AmtButton
								size="small"
								variant="contained"
								onClick={() => {
									window.location.href = `${window.location.origin}/`;
								}}
								className={classes.button}
							>
								{t('app.back.app')}
							</AmtButton>
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return this.props.children;
	}
}

export default withTranslation()(withStyles(styles)(ErrorBoundary));
