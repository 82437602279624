import { Logger, Hub } from 'aws-amplify';

const logger = new Logger('ServiceWorkerConfig');

class ServiceWorkerConfig {
	constructor() {
		this.installingWorker = null;
		this.registration = null;
		Hub.listen('sw', this.listenServiceWorkerEvents, 'ServiceWorkerConfigListener');
	}

	onUpdate = (registration, installingWorker) => {
		logger.debug('onUpdate - scope', registration.scope);
		logger.debug('onUpdate - installingWorker', installingWorker);
		this.registration = registration;
		this.installingWorker = installingWorker;
		logger.debug('onUpdate - this.installingWorker', this.installingWorker);
		// AMT Event for onUpdate
		logger.debug('New content is available; please refresh.');
		Hub.dispatch(
			'sw',
			{ event: 'cacheStatus', data: 'updateAvailable' },
			'ServiceWorkerConfig',
		);
	};

	onSuccess = registration => {
		logger.debug('onSuccess - scope', registration.scope);
		// AMT Event for onUpdate
		logger.debug('Content is cached for offline use.');
		Hub.dispatch(
			'sw',
			{ event: 'cacheStatus', data: 'contentCached' },
			'ServiceWorkerConfig',
		);
	};

	// Default handler for listening events
	listenServiceWorkerEvents = data => {
		const { channel, payload } = data;
		if (channel === 'sw') {
			this.onServiceWorkerEvent(payload);
		}
	}

	onServiceWorkerEvent = payload => {
		const { event, data } = payload;
		logger.debug('onServiceWorkerEvent - data:', data);
		logger.debug('onServiceWorkerEvent - event:', event);
		switch (event) {
			case 'swAction':
				logger.debug('installingWorker:', this.installingWorker);
				logger.debug('registration:', this.registration);
				if (data === 'update') {
					logger.debug('installingWorker.postMessage');
					this.installingWorker.postMessage({
						action: 'skipWaiting',
						type: 'SKIP_WAITING'
					});
				}
				break;
			default:
				logger.debug('default');
				logger.debug('event:', event);
		}
	};
}

const instance = new ServiceWorkerConfig();
Object.freeze(instance);

export default instance;
