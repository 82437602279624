import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Transition } from 'react-transition-group';

import { AmtButton } from '../common/AmtButton';
import config from '../../config';
import Splash from '../Splash';

const styles = theme => ({
	grid: {
		height: '85vh',
	},
	buttonItem: {
		paddingTop: '1em',
		[theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '80%',
		},
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
		[theme.breakpoints.up('lg')]: {
			width: '30%',
		},
	},
	background: {
		height: '100%',
		width: '100%',
	},
	root: {
		height: '100%',
		width: '100%',
	},
	title: {
		color: '#E63837',
		whiteSpace: 'pre-wrap',
		marginBottom: '2vh',
		fontWeight: 'bold',
		textAlign: 'center',
		margin: 'auto',
		[theme.breakpoints.up('xs')]: {
			fontSize: '33px',
			lineHeight: '34px',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '41px',
			lineHeight: '42px',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '47px',
			lineHeight: '48px',
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: '55px',
			lineHeight: '56px',
		},
	},
	description: {
		color: 'black',
		margin: 'auto',
		[theme.breakpoints.up('xs')]: {
			fontSize: '12px',
			lineHeight: '14px',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '14px',
			lineHeight: '16px',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '15px',
			lineHeight: '17px',
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: '17px',
			lineHeight: '19px',
		},
	},
	button: {
		width: '80%',
		display: 'flex',
		margin: 'auto',
		padding: '10px',
		maxWidth: '360px',
	},
	bottomImage: {
		backgroundImage: 'url(' + config.app.baseUrl + '/shared/images/vegetables_homepage.svg)',
		backgroundSize: 'contain',
		backgroundPosition: 'bottom',
		backgroundRepeat: 'no-repeat',
		height: '50vh',
		bottom: 0,
		position: 'fixed',
		width: '100%',
		zIndex: -1,
	},
});

//const logger = new Logger('PreSignIn');

const defaultStyle = {
	maxHeight: 0,
	opacity: 0,
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
};

const transitionStyles = {
	entered: {
		maxHeight: '500px',
		opacity: 1,
		transition: 'max-height 2000ms ease-in-out, opacity 2000ms',
	},
};

const PreSignIn = ({ onStateChange, classes }) => {
	const { t } = useTranslation();
	const [beginTransition, setBeginTransition] = useState(false);

	useEffect(() => {
		setBeginTransition(true);
	}, []);
	return (
		<div className={classes.background}>
			<Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
				<Grid item>
					<Splash />
				</Grid>
				<Transition in={beginTransition} timeout={0}>
					{state => (
						<div
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}
						>
							<Grid item>
								{t('app.addHomeSreen.features.shortContent')
									.split('\n')
									.map(feat => (
										<Typography variant="body1" component="h2" align="center">
											{feat}
										</Typography>
									))}
							</Grid>
							<Grid item className={classes.buttonItem}>
								<Grid container item xs>
									<AmtButton
										size="small"
										color="primary"
										variant="contained"
										onClick={() => onStateChange('signUp')}
										className={classes.button}
									>
										{t('app.auth.signup.action')}
									</AmtButton>
								</Grid>
							</Grid>
							<Grid item className={classes.buttonItem}>
								<Grid container item xs>
									<AmtButton
										size="small"
										color="primary"
										variant="outlined"
										onClick={() => onStateChange('signIn')}
										className={classes.button}
									>
										{t('app.auth.signin.action')}
									</AmtButton>
								</Grid>
							</Grid>
						</div>
					)}
				</Transition>
			</Grid>
			<div className={classes.bottomImage} />
		</div>
	);
};

PreSignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PreSignIn);
