import React from 'react';

export const AuthContext = React.createContext('user');

// This function takes a component...
export function withAuthContext(Component) {
	// ...and returns another component...
	return function WithAuthComponent(props) {
		// ... and renders the wrapped component with the context theme!
		// Notice that we pass through any additional props as well
		return (
			<AuthContext.Consumer>
				{ctx => <Component {...props} authCtx={ctx} />}
			</AuthContext.Consumer>
		);
	};
}

export default {
	AuthContext,
	withAuthContext,
};
