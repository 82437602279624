import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import FormTextField from '../form/FormTextField';

import AuthLayout from './AuthLayout';
import { styles } from './AuthStyles';

const logger = new Logger('ConfirmSignIn');

class ConfirmSignIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mfaType: 'SMS',
			error: '',
		};
	}

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	confirm = async values => {
		const user = this.props.authData;
		const { code } = values;
		const mfaType = user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;
		Auth.confirmSignIn(user, code, mfaType)
			.then(() => this.changeState('signedIn'))
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	componentDidUpdate() {
		//logger.debug('component did update with props', this.props);
		const user = this.props.authData;
		const mfaType = user && user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'TOTP' : 'SMS';
		if (this.state.mfaType !== mfaType) this.setState({ mfaType });
	}

	//Validate if mandatory fields are filled
	validate = values => {
		const errors = {};
		if (!(values.code && values.code.length > 0)) {
			errors.code = 'app.error.mandatoryField';
		}
		return errors;
	};

	render() {
		const { classes, t } = this.props;

		return (
			<AuthLayout title={t('app.auth.confirmSignIn.title') + ' ' + this.state.mfaType}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}
					<Form
						onSubmit={this.confirm}
						validate={this.validate}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit}  className={classes.form}>
								<Grid container spacing={2} direction="column" justify="center" alignItems="center">
									<Grid item>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="code"
											name="code"
											type="text"
											label={t('app.auth.code.label')}
											autoFocus={true}
										/>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											color="primary"
											type="submit"
											className={classes.actionBtn}
											disabled={submitting || pristine || invalid}
										>
											{t('app.actions.confirm')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					/>
				</Fragment>
			</AuthLayout>
		);
	}
}

ConfirmSignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(ConfirmSignIn));
