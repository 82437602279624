import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

export default function CGUContent(props) {
	const { t } = useTranslation();
	return (
		<Fragment>
			{/* Intro */}
			<Typography variant="body2" gutterBottom>
				{t('cgu.intro.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.intro.content2')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.intro.content3')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.intro.content4')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.intro.content5')}
			</Typography>
			{/* Article 1 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article1.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article1.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article1.content2')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article1.content3')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article1.content4')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article1.content5')}
			</Typography>
			{/* Article 2 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article2.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content1')}
			</Typography>
			<ul>
				<li>
					<Typography variant="body2" gutterBottom>
						{t('cgu.article2.content2')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom>
						{t('cgu.article2.content3')}
					</Typography>
				</li>
			</ul>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content4')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content5')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content6')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content7')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article2.content8')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article2.content9')}
			</Typography>
			{/* Article 3 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article3.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article3.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article3.content2')}
			</Typography>
			<ul>
				<li>
					<Typography variant="body2" gutterBottom>
						{t('cgu.article3.content3')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content4')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content5')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content6')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content7')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content8')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content9')}
					</Typography>
				</li>
			</ul>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article3.content10')}
			</Typography>
			<ul>
				<li>
					<Typography variant="body2" gutterBottom>
						{t('cgu.article3.content11')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content12')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content13')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content14')}
					</Typography>
				</li>
			</ul>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article3.content15')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article3.content16')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article3.content17')}
			</Typography>
			<ul>
				<li>
					<Typography variant="body2" gutterBottom>
						{t('cgu.article3.content18')}
					</Typography>
				</li>
				<li>
					<Typography variant="body2" gutterBottom paragraph={true}>
						{t('cgu.article3.content19')}
					</Typography>
				</li>
			</ul>
			{/* Article 4 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article4.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article4.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article4.content2')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article4.content3')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article4.content4')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article5.content5')}
			</Typography>
			{/* Article 5 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article5.title')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article5.content1')}
			</Typography>
			{/* Article 6 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article6.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article6.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article6.content2')}
			</Typography>
			{/* Article 7 */}
			<Typography variant="h6" gutterBottom paragraph={true}>
				{t('cgu.article7.title')}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t('cgu.article7.content1')}
			</Typography>
			<Typography variant="body2" gutterBottom paragraph={true}>
				{t('cgu.article7.content2')}
			</Typography>
		</Fragment>
	);
}
