import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import config from '../config';

//CSS
const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 16,
		paddingBottom: 16,
		width: '100%',
	},

	progress: {
		margin: 'auto',
		display: 'block',
	},

	grid: {
		height: '85vh',
	},
	media: {
		[theme.breakpoints.up('xs')]: {
			height: 200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		[theme.breakpoints.up('sm')]: {
			height: 300,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	background: {
		height: '100%',
		width: '100%',
	},
	bottomImage: {
		backgroundImage: 'url(' + config.app.baseUrl + '/shared/images/vegetables_homepage.svg)',
		backgroundSize: 'contain',
		backgroundPosition: 'bottom',
		backgroundRepeat: 'no-repeat',
		height: '50vh',
		bottom: 0,
		position: 'fixed',
		width: '100%',
		zIndex: -1,
	},
});

const AppLoading = ({ classes }) => {
	const { t } = useTranslation();

	return (
		<div className={classes.background}>
			<Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
				<Grid item>
					<img src={config.app.baseUrl + '/shared/images/logo.png'} className={classes.media} alt="" />
				</Grid>
				<Grid item>
					<CircularProgress color="secondary" />
				</Grid>
				<Grid item>
					<Typography variant="h6" component="h2" color="primary" align="center">
						{t('app.loading')}
					</Typography>
				</Grid>
			</Grid>
			<div className={classes.bottomImage} />
		</div>
	);

}

AppLoading.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppLoading);
