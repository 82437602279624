import React, { Fragment } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import CGUContent from '../components/CGUContent';

const PrivacyPolicy = ({ t }) => {

	return (
		<Fragment>
			<Paper style={{ padding: '20px', margin: '20px' }}>
				<Typography variant="h2" component="div" gutterBottom align="center">
					{t('app.auth.cgu.link')}
				</Typography>
				<CGUContent />
			</Paper>
		</Fragment>
	);
};

export default withTranslation()(PrivacyPolicy);
