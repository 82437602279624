import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import FormTextField from '../form/FormTextField';

import { AmtButton } from '../common/AmtButton';
import AuthLayout from './AuthLayout';
import { styles } from './AuthStyles';

const logger = new Logger('ConfirmSignUp');

class ConfirmSignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	gotoSignIn = () => {
		// to switch the authState to 'signIn'
		logger.debug('gotoSignIn');
		this.props.onStateChange('signIn', {});
	};

	confirm = async values => {
		const email = this.props.authData.email || values.email;
		const { code } = values;
		Auth.confirmSignUp(email, code)
			.then(() => this.props.onStateChange('signIn', {}))
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	resend = () => {
		const email = this.props.authData.email || this.inputs.email;
		Auth.resendSignUp(email)
			.then(() => logger.debug('code resent'))
			.catch(err => {
				logger.error(err);
				this.setState({ error: err.code });
			});
	};

	//Validate if mandatory fields are filled
	validate = values => {
		const errors = {};
		if (!(values.code && values.code.length > 0)) {
			errors.code = 'app.error.mandatoryField';
		}
		return errors;
	};

	render() {
		const { classes, authData, t } = this.props;
		const initValues = {
			email: authData.email && authData.email.length > 0 ? authData.email : '',
		};

		return (
			<AuthLayout title={t('app.auth.confirmSignUp.title')}>
				<Fragment>
					{this.state.error && this.state.error.length > 0 && (
						<div className={classes.messages}>
							<Typography color="error" align="center" variant="body2">
								{t('auth.error.' + this.state.error)}
							</Typography>
						</div>
					)}

					<Form
						onSubmit={this.confirm}
						validate={this.validate}
						initialValues={initValues}
						render={({ handleSubmit, reset, submitting, pristine, invalid, values }) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<Grid container spacing={0} direction="column" justify="center" alignItems="center">
									<Grid container item justify="center" className={classes.formItem}>
										<Typography align="center" variant="h6" className={classes.field}>
											{t('app.auth.confirmSignup.welcome')}
										</Typography>
										<Typography align="left" variant="subtitle1" className={clsx(classes.field, classes.subtitle)}>
											{t('app.auth.confirmSignup.subtitle')}
										</Typography>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											disabled={authData.email && authData.email.length > 0 ? true : false}
											id="email"
											name="email"
											type="text"
											label={t('app.auth.email.label')}
											className={classes.field}
											autoFocus={true}
										/>
									</Grid>
									<Grid container item className={classes.formItem}>
										<Field
											fullWidth
											required
											component={FormTextField}
											id="code"
											name="code"
											type="password"
											label={t('app.auth.code.label')}
											className={classes.field}
										/>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											variant="contained"
											color="primary"
											type="submit"
											className={classes.button}
											disabled={submitting || pristine || invalid}
										>
											{t('app.actions.confirm')}
										</AmtButton>
									</Grid>
									<Grid container item className={classes.formItem}>
										<AmtButton
											variant="contained"
											color="secondary"
											className={classes.button}
											disabled={!values.email || values.email === 0 ? true : false}
											onClick={this.resend}
										>
											{t('app.auth.actions.resend')}
										</AmtButton>
									</Grid>
									<Grid container item justify="flex-end" className={classes.formItem}>
										<Button color="primary" onClick={this.gotoSignIn} className={classes.field}>
											{t('app.auth.signin.link.signIn')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					/>
				</Fragment>
			</AuthLayout>
		);
	}
}

ConfirmSignUp.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(ConfirmSignUp));
