import React, { Fragment } from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

let id = 0;

export default ({ input: { name, label, onChange, value, ...restInput }, placeholder, meta, errorlist, ...rest }) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<FilledInput
				{...rest}
				name={name}
				error={meta.error && meta.touched}
				inputProps={restInput}
				onChange={onChange}
				value={value}
			/>
			{!errorlist ? (
				<FormHelperText error={meta.error}>{meta.error ? t(meta.error) : t(placeholder)}</FormHelperText>
			) : (
					meta.error && meta.dirty && meta.error.map((element) => (
						<Typography color="error" variant="caption" component="h2" key={id++}>
							{t(element)}
						</Typography>
					))
				)}
		</Fragment>
	);
}